import Swiper from "swiper";
import SwiperCore, { Navigation, Controller, Autoplay } from "swiper/core";

SwiperCore.use([Navigation, Controller, Autoplay]);

(function () {
  // const setSlideHeight = (that) => {
  //   const card = that.$el[0];
  //   for (let slide of card.querySelectorAll(".swiper-slide")) {
  //     slide.style.height = "auto";
  //   }
  //   var currentSlide = that.activeIndex;
  //   var newHeight = that.slides[currentSlide].clientHeight;
  //   console.log(newHeight);
  //   // debugger;

  //   for (let slide of card.querySelectorAll(".swiper-slide, .swiper-wrapper")) {
  //     slide.style.height = `${newHeight}px`;
  //   }
  //   console.log(card.querySelector(".swiper-wrapper"));
  //   that.update();
  // };

  const cardsList = document.getElementsByClassName("advantage-card");
  const subSwiperList = [];

  if (!cardsList.length) {
    return;
  }

  for (let card of cardsList) {
    const cardsSwiper = new Swiper(card, {
      //   direction: "vertical",
      loop: true,
      initialSlide: 1,
      slideActiveClass: "activating",
      slideDuplicateActiveClass: "activating",
      allowTouchMove: false,
      loopedSlides: 9,
      spaceBetween: 20,
      // autoHeight: true,
      on: {
        // init: function () {
        //   setSlideHeight(this);
        // },
        // slideChangeTransitionEnd: function () {
        //   setSlideHeight(this);
        // },
      },
    });
    subSwiperList.push(cardsSwiper);
  }

  const swiper = new Swiper("#advantage-controls", {
    direction: "vertical",
    loop: true,
    navigation: {
      nextEl: ".arrow-down",
      prevEl: ".arrow-up",
    },
    initialSlide: 1,
    slideActiveClass: "activating",
    slideDuplicateActiveClass: "activating",
    height: 120,
    slideToClickedSlide: true,
    centeredSlides: true,
    slidesPerView: 3,
    loopedSlides: 9,
    controller: {
      by: "container",
      control: subSwiperList,
      //   inverse: 1,
    },
    autoplay: {},
  });

  document
    .querySelector(".advantage-container")
    .addEventListener("mouseenter", () => {
      swiper.autoplay.stop();
      console.log("stopping autoplay");
    });
  document
    .querySelector(".advantage-container")
    .addEventListener("mouseleave", () => {
      swiper.autoplay.start();
      console.log("starting autoplay");
    });
})();
